.mainConteiner {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(8px) brightness(75%);
  cursor: pointer;
  transition: background 0.1s ease-out 0s;
  flex-shrink: 0;
  opacity: 1;
}
.mainConteinerDark {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  border: none;
  background-color: rgba(14, 14, 14, 0.8);
  backdrop-filter: blur(8px) brightness(75%);
  cursor: pointer;
  transition: background 0.1s ease-out 0s;
  flex-shrink: 0;
  opacity: 1;
}

.mainConteiner:hover {
  background-color: rgba(255, 255, 255, 0.68);
}

.upBlock {
  position: absolute;
  inset: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.downBlock {
  position: absolute;
  inset: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.upBlockImg {
  width: 1.25rem;
  fill: #fff;
}

.liked {
  background-color: rgba(255, 0, 0, 0.65) !important;
}

.downBlockImg {
  width: 1.25rem;
  fill: #fff;
}
@media screen and (max-width: 639px) {
  .mainConteiner {
    width: 2.5rem;
    height: 2.5rem;
  }
  .upBlockImg {
    fill: black;
  }
}
