.mainConteiner {
  scroll-snap-align: start;
}

.mainConteiner:first-child {
  margin-left: 0;
}

.card {
  user-select: none;
  display: block;
  color: #1b5e3b;
  cursor: pointer;
}

.imgConteiner {
  z-index: 0;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
}
.coneinerBoss {
  width: 100%;
}

.imgBlock {
  position: relative;
  width: 100%;
  height: 6rem;
}

.imgBlock:hover {
  transform: scale(1.03) translateZ(0);
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.img {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  position: absolute;
  height: 100%;
  object-fit: cover;
}

.descriptionBlock {
  display: flex;
  width: 100%;
  padding: 0.25rem;
  align-items: center;
  position: relative;
  background-color: var(--other-background-color);
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 5rem;
}
.productName {
  color: black;
}
.fontName {
  font-weight: bold;
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  text-align: start;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
}

.count {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--text-color);
  text-align: start;
  margin: 0.7rem 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 1.25rem;
}

.mainCardBlock {
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0px, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
  transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s, background 100ms ease-out 0s;
}

.mainCardBlock:hover {
  transform: scale(1.03) translateZ(0);
}

.downBlockDescription {
  height: 3rem;
  white-space: nowrap;
  position: relative;
  font-size: 12px;
  color: rgba(0, 0, 0);
  padding: 4px;
}
.dotted {
  border-top: 2px dotted rgba(0, 0, 0, 0.13);
}
.downShopName {
  opacity: (0.5);
}
.downShopDelivery {
  opacity: (0.5);
}
.downShopTaks {
  color: #0f99d3;
}
.dotTitl {
  opacity: (0.5);
  margin-left: 3px;
  margin-right: 3px;
}
.skeleton {
  position: relative;
  width: 100%;
  min-height: 200px;
  top: -40px;
}