.mainConteiner {
  position: fixed;
  z-index: 9999999;
  background: rgba(128, 128, 128, 0.35);
  height: 100vh;
  width: 100vw;
  left: 0;
  bottom: 0;
}
.contentBlock {
  box-sizing: border-box;
  margin: 20px;
  max-width: 520px;
  max-height: 650px;
  width: 55vw;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 640px) {
    overflow: hidden;
    min-width: unset;
    width: 100%;
    background-color: var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: fixed;
    bottom: 0;
    height: fit-content;
    padding: 0 0;
    margin:0;
    border-radius: 10px 10px 0 0 ;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}
.imgBlock {
  height: 30%;
}
.imageOrder {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 850px) {
    background-size: contain;
    object-fit: cover;
    height: 260px;
  }
}
.add-button--element {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.85rem;
  line-height: 1.2rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;

}
.add-button--element{
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
}
.mainBtn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  padding: 1rem;
  width: calc(100% - 1rem);
  cursor: pointer;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  height: 55px !important;
  box-sizing: border-box !important;
  color: white;
  &--element {
    color: var(--blue-btn-text-color);
  }
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.contentBlockMain {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 0.5rem;
  height: 35vh;
}
.topContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 1.5rem;
}
.productName {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.productCost {
  color: #1b5e3b;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.conteinerInfo {
  display: flex;
  flex-direction: column;
}
.titleDown {
  font-weight: bold;
  font-size: 0.8rem;
  opacity: 0.5;
  padding: 0 0.5rem;
}