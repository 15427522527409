.hide {
  display: none;
}
.filtersBlock {
  margin: 0px;
  margin-top: 3.7rem;
  overflow: hidden;
  border: none;
  position: relative;
  bottom: 2rem;
  height: 100%;
}
.filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  row-gap: 1rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
}
.paddingFields {
  padding: 0.5rem;
}
.mainBlockNotFound{
  height: calc(100vh - 20rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notFoundConteiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.notFoundImg {
  height: 20rem;
  margin-right: 3rem;
  width: 100%;
}
.textNotFound {
  font-weight: 800;
  font-size: 1.6rem;
}
.bigBaseProducts {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.titleAllProd {
  font-size: 1.3rem;
  width: 100%;
  font-weight: bold;
}
.btnMoreInfo {
  color: #1b5e3b;
  background-color: rgba(2, 172, 248, 0.1);
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
}
.swiperBarBox {
  display: flex;
  flex-direction: column;
  height: 25%;
  width: 100%;
}
.titleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.titleRowButton {
  padding: 5px 10px;
  border: none;
  background: rgba(0, 157, 224, 0.1984313725);
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-transform: none;
  color: #1b5e3b;
  user-select: inherit;
  cursor: pointer;
  text-decoration: none;
  margin-inline-start: 0;
  border-radius: 0.5rem;
}
.titleRowText {
  font-size: 1.3rem;
  font-weight: bold;
}
.blockChips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  justify-content: center;
  width: 100%;
}
.conteinerItems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.productConteiner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}
.titleProductConteiner {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-bottom: 0.5rem;
}
.titleProduct {
  font-size: 1.3rem;
  font-weight: bold;
}
